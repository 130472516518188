<script>
import api from "@/api";
import Loader from "vue-element-loading";
import GeneralMixin from "@/mixins/General.js";

export default {
  components: {
    Loader,
  },
  data() {
    return {
      loading: false,
      pendingApprovals: [],
    };
  },
  methods: {
    getSlProduct(item) {
      if (item.product_obj) {
        return item.product_obj;
      }
      return item.space_location.sl_product_obj;
    },
    isSameDay(val1, val2) {
      const date1 = val1.split("T")[0];
      const date2 = val2.split("T")[0];
      return date1 === date2;
    },
    makeReadable(v, type) {
      if (type === "d") {
        return GeneralMixin.makeReadableDate(v);
      } else if (type === "t") {
        return GeneralMixin.makeReadableTime(v);
      }
      return GeneralMixin.makeReadableDateTime(v);
    },
    approve(paItem) {
      return this.updateScheduleItem(paItem, { active: 1 });
    },
    cancel(paItem) {
      return this.updateScheduleItem(paItem, { cancelled: 1 });
    },
    updateScheduleItem(paItem, data) {
      this.loading = true;
      return api.Util.axios
        .patch(`/schedules/${paItem.id}/`, data)
        .then(() => {
          let index = -1;
          for (let tPaIndex in this.pendingApprovals) {
            const tPa = this.pendingApprovals[tPaIndex];
            if (tPa.id == paItem.id) {
              index = tPaIndex;
            }
          }
          console.log(index);
          if (index > -1) {
            this.pendingApprovals.splice(index, 1);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getPendingApprovals() {
      this.loading = true;
      return api.Util.axios("/schedules/get_pending_approvals/")
        .then((response) => {
          this.pendingApprovals = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getPendingApprovals();
  },
};
</script>
<template>
  <div class="py-2">
    <Loader :active="loading" />
    <h4 class="d-none d-md-block">Approve Bookings</h4>
    <div class="table-parent">
      <div v-if="pendingApprovals.length == 0" class="text-center empty">
        <i class="fas fa-trash"></i>
        <br />
        So Much Empty!
      </div>
      <table class="table table-bordered" v-else>
        <thead>
          <tr>
            <th>Space</th>
            <th>Title</th>
            <th>Booker</th>
            <th>Product</th>
            <th>Times</th>
            <th>Notes</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="paItem of pendingApprovals" :key="paItem.id">
            <td>{{ paItem.space_location.name }}</td>
            <td>{{ paItem.name }}</td>
            <td>
              <div v-if="paItem.booker">
                <div v-if="paItem.booker.name">{{ paItem.booker.name }}</div>
                <div v-else>{{ paItem.booker.email }}</div>
                <div v-if="paItem.booker.phone">
                  <i class="fas fa-phone"></i>
                  {{ paItem.booker.phone }}
                </div>
              </div>
            </td>
            <td>
              <span
                :class="{
                  'bg-danger text-white p-1 rounded':
                    !paItem.product_obj.is_default,
                }"
                >{{ paItem.product_obj.name }}</span
              >
              <div>KES {{ paItem.requested_amount }} /=</div>
            </td>
            <td>
              <template v-if="isSameDay(paItem.start_time, paItem.end_time)">
                <div>
                  {{ makeReadable(paItem.start_time, "d") }}
                </div>
                <div>
                  From:
                  <span class="time">{{
                    makeReadable(paItem.start_time, "t")
                  }}</span>
                  To:
                  <span class="time">{{
                    makeReadable(paItem.end_time, "t")
                  }}</span>
                </div>
              </template>
              <template v-else>
                <div>From: {{ makeReadable(paItem.start_time) }}</div>
                <div>To: {{ makeReadable(paItem.end_time) }}</div>
              </template>
            </td>
            <td>
              <div class="col" v-if="paItem.notes">
                {{ paItem.notes }}
              </div>
              <div v-else>No Notes</div>
            </td>
            <td>
              <div class="row">
                <div class="col">
                  <router-link
                    :to="{ name: 'ScheduleItem', params: { ID: paItem.id } }"
                    class="btn btn-primary"
                  >
                    <i class="fas fa-eye"></i>
                    View
                  </router-link>
                </div>
                <div class="col">
                  <button
                    class="btn btn-success"
                    @click.prevent="approve(paItem)"
                  >
                    <i class="fas fa-check"></i>
                    Approve
                  </button>
                </div>
                <div class="col">
                  <button
                    class="btn btn-danger"
                    @click.prevent="cancel(paItem)"
                  >
                    <i class="fas fa-times"></i>
                    Cancel
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.empty {
  font-size: 20px;
  color: #666;
}
span.time {
  background: rgb(204, 203, 203);
  padding: 3px;
  border-radius: 3px;
}
</style>
